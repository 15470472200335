import React, { useState, useEffect } from "react";

import { className, emailRegex } from "../../Utils";
import { SERVER_URL } from "../../config/serverUrl";

import "./WebBasedContatForm.css";


const WebBasedContatForm = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    productType: "",
  });
  const [error, setError] = useState({
    email: false,
    fields: false,
  });
  const [successMessage, setSuccessMesage] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Track dropdown state
  const [isFormValid, setIsFormValid] = useState(false);
  const productTypes = [
    "Застраховка пътуване в чужбина",
    "Застраховка моят дом",
    "Застраховки живот и злополука",
    "Здравни застраховки",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form field
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  
    setError({ email: false, fields: false });

    setIsFormValid(
      emailRegex.test(name === "email" ? value : form.email) && // Use the updated value for email validation
      (name === "name" ? value : form.name).length > 0 &&
      (name === "productType" ? value : form.productType).length > 0
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => setSuccessMesage(false), 3000);
    return () => clearTimeout(timeout);
  }, [successMessage]);

  const sendEmail = async (data) => {
    try {
      const result = await fetch(`${SERVER_URL}/admin/send/webBasedContactForm`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (result?.status === 200) {
        setSuccessMesage(true);
      }
    } catch (err) {
      console.log(err.message + err.code);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, productType } = form;

    // Validate email using regex
    if (!emailRegex.test(email)) {
      setError((prev) => ({ ...prev, email: true }));
      return;
    }

    // Validate if all fields are filled
    if (!name || !email || !productType) {
      setError((prev) => ({ ...prev, fields: true }));
      return;
    }

    sendEmail(form);
    setForm({
      name: "",
      email: "",
      productType: "",
    });
    setIsFormValid(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(state => !state);
  };

  const handleProductTypeSelect = (productType) => {
    setForm((prev) => ({ ...prev, productType }));
    setDropdownOpen((state) => !state);

    // Check if form is valid after selecting product type
    setIsFormValid(
      emailRegex.test(form.email) &&
        form.name.length > 0 &&
        form.email.length > 0 &&
        productType.length > 0
    );
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className={className("form")}>
        <h1 className={className("form-title")}>Изпрати запитване</h1>
        <p className={className("form-description")}>Моля, изберете продукта, от който се интересувате. Служител на Allianz ще се свърже Вас на посочения email.</p>
        <input
          className={className("form-input")}
          type="text"
          name="name"
          placeholder="Име"
          value={form.name}
          onChange={handleChange}
        />
        <input
          className={className("form-input")}
          type="email"
          name="email"
          placeholder="Имейл адрес"
          value={form.email}
          onChange={handleChange}
        />
        {error.email && (
          <p className={className("form-error")}>Моля въведете валиден имейл адрес.</p>
        )}

        <div className={className("dropdown")}>
          <div
            className={className("dropdown-header")}
            onClick={handleDropdownToggle}
          >
            {form.productType || "Изберете Продукт"}
          </div>
          {dropdownOpen && (
            <ul className={className("dropdown-list")}>
              {productTypes.map((type, index) => (
                <li
                  key={index}
                  className={className("dropdown-item")}
                  onClick={() => handleProductTypeSelect(type)}
                >
                  {type}
                </li>
              ))}
            </ul>
          )}
        </div>

        {error.fields && (
          <p className={className("form-error")}>Моля попълнете всички полета!</p>
        )}

        {successMessage && (
          <p className={className("contacts-message-success")}>
            Вашата форма е изпратена успешно!
          </p>
        )}
        
        <button
          className={className(`form-button`) + ' ' + className(isFormValid ? "active-button" : "")}

        >
          ИЗПРАТИ
        </button>
      </form>
    </div>
  );
};

export default WebBasedContatForm;
